





















































import { Component, Vue } from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import Footer from '@/components/Footer/Footer.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';

import ThankYouPaymentLoading from './ThankYouPaymentLoading.vue';
import ThankYou from '@/pages/Shopping/components/ThankYou/index.vue';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

import PaymentService from '@/services/Payment/PaymentService';

import { PaymentSuccess } from '@/pages/Shopping/interfaces/IPayment';

const PAYMENT_STATUS_PAID = 'paid';

const MESSAGE_LOADING_PAYMENT = 'Aguarde, o seu pagamento que está sendo processado.';
const MESSAGE_ERROR_PAYMENT = 'Volte mais tarde, seu pagamento ainda está sendo processado.';

const TIME_INTERVAL_TEN_SECONDS = 10000;
const NUMBER_ATTEMPTS_GET_PAYMENT = 6;

const INDEX_TAB_MENU_PROFILE_PLAN = 2;

@Component({
  components: {
    PageHeader,
    Footer,
    ThankYouPaymentLoading,
    ThankYou,
    FeedbackUser
  }
})
export default class ThankYouPayment extends Vue {
  private isLoading = false;
  private isPayment = false;

  private timeout = 0;
  private currentAttemptToGetPayment = 0;

  private paymentData: PaymentSuccess | null = null;

  private PaymentService = new PaymentService();

  created() {
    this.getPaymentProgress();
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get feedbackMessage() {
    return this.isError ? MESSAGE_ERROR_PAYMENT : MESSAGE_LOADING_PAYMENT;
  }

  get isError() {
    return !!(this.currentAttemptToGetPayment >= NUMBER_ATTEMPTS_GET_PAYMENT && !this.isPayment);
  }

  async getPaymentProgress() {
    this.currentAttemptToGetPayment += 1;

    await this.getPayment();

    if (!this.isPayment && this.currentAttemptToGetPayment <= NUMBER_ATTEMPTS_GET_PAYMENT) {
      this.timeout = setTimeout(this.getPaymentProgress, TIME_INTERVAL_TEN_SECONDS);

      return;
    }

    this.clearTimeout();
  }

  async getPayment() {
    try {
      this.isLoading = true;

      const paymentID = this.$route.params.idPurchase;

      if (paymentID !== null) {
        const [payment]: PaymentSuccess[] = await this.PaymentService.getPayment({
          paymentID: Number(paymentID)
        });

        if (payment) {
          this.paymentData = payment;
          this.isLoading = false;

          this.isPayment = payment.payment.status_external === PAYMENT_STATUS_PAID;

          this.setTrackAmplitude();

          return;
        }

        this.isPayment = false;
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao retornar os dados do pagamento.',
        status: 'error'
      });

      console.error({ error });
    }
  }

  clearTimeout() {
    clearTimeout(this.timeout);
    this.isLoading = false;
  }

  redirectToDashboard() {
    this.$router.push({ name: 'Dashboard' });
  }

  setTrackAmplitude() {
    InstanceAmplitude.setTrack({
      eventName: 'page_view',
      additionalAttrs: {
        data: {
          component: 'RenewThankYouPayment'
        }
      }
    });
  }

  redirectPagePlan() {
    this.$store.commit('setTabProfile', INDEX_TAB_MENU_PROFILE_PLAN);

    return '/profile';
  }

  setBreadCrumbs() {
    this.$breadcrumb.set([
      { title: 'Plano', to: this.redirectPagePlan() },
      { title: 'Obrigado', to: null }
    ]);
  }
}
